"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.additionalWordsBySlug = void 0;
exports.additionalWordsBySlug = {
    'Chi_veschi': [
        { index: 0, additionalWords: ['мама', 'мамы'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 1, additionalWords: ['маша', 'маши'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 2, additionalWords: ['папа', 'папы'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 3, additionalWords: ['саша', 'саши'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 4, additionalWords: ['ваня', 'вани'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 5, additionalWords: ['коля', 'коли'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 6, additionalWords: ['катя', 'кати'], sliceCount: 3, skip: 0, correctWord: 'котик' },
    ],
    'Plural_verbs_2': [
        { index: 0, additionalWords: ['сидят'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 1, additionalWords: ['поют'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 2, additionalWords: ['летят'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 3, additionalWords: ['едет'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 4, additionalWords: ['танцует', 'танцы'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 5, additionalWords: ['ест', 'едут', 'еда'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 6, additionalWords: ['бежит', 'бег'], sliceCount: 3, skip: 0, correctWord: 'котик' },
    ],
    'chasti-tela': [
        { 'index': 0, 'word': 'Рука', 'additionalWords': ['руки', "рука"], 'sliceCount': 2, 'skip': 0, 'correctWord': 'рука' },
        { 'index': 1, 'word': 'Руки', 'additionalWords': ['рука', "руки"], 'sliceCount': 2, 'skip': 2, 'correctWord': 'руки' },
        { 'index': 2, 'word': 'Нога', 'additionalWords': ['ноги', "нога"], 'sliceCount': 2, 'skip': 4, 'correctWord': 'нога' },
        { 'index': 3, 'word': 'Ноги', 'additionalWords': ['нога', "ноги"], 'sliceCount': 2, 'skip': 6, 'correctWord': 'ноги' },
        { 'index': 4, 'word': 'туловище', 'additionalWords': ['туловище'], 'sliceCount': 1, 'skip': 8, 'correctWord': 'туловище' },
        { 'index': 5, 'word': 'голова', 'additionalWords': ['голова'], 'sliceCount': 1, 'skip': 9, 'correctWord': 'голова' },
        { 'index': 6, 'word': 'шея', 'additionalWords': ['шея'], 'sliceCount': 1, 'skip': 10, 'correctWord': 'шея' },
        { 'index': 7, 'word': 'Плечо', 'additionalWords': ['плечи', "плечо"], 'sliceCount': 2, 'skip': 11, 'correctWord': 'плечо' },
        { 'index': 8, 'word': 'Плечи', 'additionalWords': ['плечо', "плечи"], 'sliceCount': 2, 'skip': 13, 'correctWord': 'плечи' },
        { 'index': 9, 'word': 'Локоть', 'additionalWords': ['локти', "локоть"], 'sliceCount': 2, 'skip': 15, 'correctWord': 'локоть' },
        { 'index': 10, 'word': 'Локти', 'additionalWords': ['локоть', "локти"], 'sliceCount': 2, 'skip': 17, 'correctWord': 'локти' },
        { 'index': 11, 'word': 'Колено', 'additionalWords': ['колени', "колено"], 'sliceCount': 2, 'skip': 19, 'correctWord': 'колено' },
        { 'index': 12, 'word': 'Колени', 'additionalWords': ['колено', "колени"], 'sliceCount': 2, 'skip': 21, 'correctWord': 'колени' }
    ]
};
